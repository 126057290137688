import React, { Component } from 'react';
import styled from 'styled-components';
import MainContainer from './main-container';
import theme from '../theme';

const StyledPageSection = styled.section`
	padding: 100px 0;
	line-height: 1.75rem;

	${props => props.color && (`
		&, h2 {
			color: ${props.color};
		}
	`)}

	${props => props.backgroundColor && (`
		padding-top: 100px !important;
		margin-bottom: 100px;
		background: ${props.backgroundColor};

		@media screen and (max-width: ${theme.breakpoints.md}) {
			padding-top: 50px !important;
			margin-bottom: 50px;
		}
	`)}

	@media screen and (max-width: ${props => theme.breakpoints.md}) {
		padding: 50px 0;
	}

	& + & {
		padding-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
`;

class PageSection extends Component {

	render () {
		return (
			<StyledPageSection
				backgroundColor={this.props.backgroundColor}
				color={this.props.color}
			>
                <MainContainer>
                    {this.props.children}
                </MainContainer>
			</StyledPageSection>
		);
	}

}

export default PageSection
