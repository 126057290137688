import theme from './theme.js';

export function latoBold () {
	return `
		text-transform: uppercase;
		font-family: 'lato';
		font-weight: 600;
		letter-spacing: 1px;
	`;
}

export function formControlFont () {
	return `
		font-family: 'lato';
		text-transform: none;
		font-weight: 400;
		letter-spacing: 1.2px;
	`;
}

export const selectStyles = {
	control: (provided, state) => ({
		...provided,
		borderRadius: 0,
		borderColor: state.isFocused
			? theme.colors.blue
			: theme.colors.gray,
		borderWidth: '1px',
		marginTop: '25px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontFamily: 'lato',
		fontWeight: 400,
		letterSpacing: '1.2px'
	})
}
