import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { latoBold } from '../style-utils.js';
import theme from '../theme';


const SubTitle = styled.span`
	display: block;
	color: ${props => props.color || theme.colors.blue};
	${latoBold}
	font-size: .625rem;
	margin-bottom: 5px;
	line-height: 1.2rem;
`;

const Wrapper = styled.div`
	text-align: ${props => (props.center ? 'center' : 'left')};

	${props => (props.center && (`
		padding-bottom: 12px;


		${SubTitle} {
			max-width: 460px;
			margin: 0 auto 5px;
		}
	`))}

	h2 {
		color: ${props => props.color};

		${props => (props.singleCol && (`
			margin-bottom: 0;
		`))}
	}
`;

const SectionTitle = props => {
	return (
		<Wrapper
			center={props.center || false}
			singleCol={props.singleCol || false}
			color={props.color}
		>
			{props.subtitle && (
				<SubTitle color={props.subtitleColor || props.color}>{props.subtitle}</SubTitle>
			)}
			<h2>{props.title}</h2>
		</Wrapper>
	);
};

SectionTitle.propTypes = {
	center: PropTypes.bool,
	color: PropTypes.string,
	subtitle: PropTypes.string,
	subtitleColor: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default SectionTitle;
