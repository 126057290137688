import React, { Component } from 'react';
import Layout from '../components/layout';
import PageSection from '../components/page-section';
import SectionTitle from '../components/section-title';


class ContactPage extends Component {
	render () {
		return (
			<Layout>

				<PageSection>
					<SectionTitle
						title="Kontakta oss"
					/>
				</PageSection>
			</Layout>
		)
	}
}



export default ContactPage
