import React, { Component } from 'react'
import styled from 'styled-components'
import { space, width } from 'styled-system'

const StyledMainContainer = styled.div`
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 80px;
    ${space}
    ${width}

    @media screen and (max-width: 960px) {
        padding-left: 48px;
        padding-right: 48px;
    }
}
`;

class MainContainer extends Component {

	render () {
		return (
			<StyledMainContainer>
				{this.props.children}
			</StyledMainContainer>
		);
	}

}

export default MainContainer
